/**
 * @file 路由中语言处理-导航守卫
 */
// eslint-disable-next-line
// @ts-nocheck

// import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';

import {
  supportLang,
  setAsyncLang,
  getLanguage,
  replacePath
} from '@/lang/i18n';
import { lang, langs } from '@/config/setup';

export default [
  ({ path, query, hash, redirectedFrom }: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext):void => {
    const pathLang = path.split('/')[1];

    // 如果连接中没有语言，则使用 browser > default
    if (!supportLang(pathLang) && langs.length > 1) {
      const defaultLang = getLanguage();
      // 解决重定向路由直接在浏览器输入时，无法跳转到链接中对应的语音问题
      const redirectPath = !!redirectedFrom?.path ? redirectedFrom?.path.split('/')[1] : '';

      return next({
        path: replacePath(path, supportLang(redirectPath) ? redirectPath : defaultLang),
        query,
        hash
        // replace: true
      });
    }

    // 设置语言
    setAsyncLang(pathLang || lang).then(() => next());
  }
];
