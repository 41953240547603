<template>
  <div :class="[`layout-${layoutStore.routeLayout}`, layoutStore.layoutClass?.layout]">
    <el-config-provider :locale="elLocale">
      <router-view
        :key="route.fullPath"
        :class="layoutStore.layoutClass?.header"
        name="header"
        v-bind="headerProps"
      />
      <router-view
        v-if="!layoutStore.iframeLink"
        :key="route.fullPath"
        v-bind="layoutStore.routeProps?.default"
      />
      <iframe
        v-if="layoutStore.iframeLink"
        id="hk-iframe"
        :key="route.fullPath"
        v-resize="myIframeResize"
        :class="fiatPageClass"
        :src="iframeFullLink"
        width="100%"
        frameborder="0"
        scrolling="no"
      />
      <router-view
        :key="route.fullPath"
        :class="layoutStore.layoutClass?.footer"
        name="footer"
        v-bind="layoutStore.routeProps?.footer"
      />
    </el-config-provider>
  </div>
  <!-- 隐藏 待地址认证弹框 -->
  <!-- <AddressVerify v-if="userStore.isLogin && kycStatus" /> -->
  <!-- 提示弹窗 -->
  <WarningCfm
    v-if="userStore.activeOutStatus || userStore.loginStatusExpire"
    :visible="userStore.activeOutStatus || userStore.loginStatusExpire"
    :type="userStore.activeOutStatus ? 3 : 1"
    @up-visible="userStore.activeOutStatus ? userStore.changeOutStatus(false) : userStore.changeLoginStatus(false)"
  />
  <!-- 其他设备登录 -->
  <WarningCfmTwo
    :visible="userStore.loginOtherEquipment"
    :type="2"
    @up-visible="userStore.changeLoginOtherEquipment(false)"
  />
  <!-- vpn弹窗提示 -->
  <VpnWarning
    :visible="userStore?.vpnWarningInfo?.visible || false"
    :type="userStore?.vpnWarningInfo?.userLevel"
    :vpn-block-info="userStore?.vpnWarningInfo"
    @up-visible="userStore?.changeVpnWarningInfo({visible: false})"
  />
  <!-- 合约开通弹框 -->
  <Contract
    :visible="contractStore?.contractVisible"
  />
</template>
<script setup lang="ts">
import WarningCfm from '@c/common/WarningCfm.vue';
import WarningCfmTwo from '@c/common/WarningCfmTwo.vue';
import VpnWarning from '@c/common/VpnWarning.vue';
import Contract from '@c/openContract/Index.vue';
import { useWindowScroll } from '@vueuse/core';
import { getCurrentLocale } from '@/lang/element';
import { useLayoutStore } from '@/store/layout';
import { getOriginPath, i18n } from '@/lang/i18n';
import { useUserStore } from '@/store/User';
import EventEmitter3 from 'eventemitter3';
// import iframeResize from 'iframe-resizer/js/iframeResizer';
import { toast } from '@/utils/toast';
import { ucApi } from '@/config/api';
import { useAssetsStore } from '@/store/assets';
import AddressVerify from './hk-components/AddressVerify.vue';
import { useKycInfoStore } from '@/store/kyc';
import CONST from '@/config/const';
import { useContractStore } from '@/store/contract';
const route = useRoute();
const layoutStore = useLayoutStore();
const userStore = useUserStore();
const assetStore = useAssetsStore();
const contractStore = useContractStore();
const { locale } = useI18n();
const router = useRouter();
const eventEmitter = new EventEmitter3;
const kyc = useKycInfoStore();

// kyc状态判断
const kycStatus = computed(() => {
  if (kyc?.kycInfo?.totalAuditStatus == CONST.KycTotalStatusEnum.PASS) {
    // 已kyclAuditStat
    return true;
  } else {
    // 未kyc
    return false;
  }
});

// iframe lin
const iframeFullLink = computed(() => {
  if (location.port) {
    // 存在port，测试环境
    return  `https://hashkeydev.com:6200${layoutStore.iframeLink}`;
  } else {
    return `${location.origin}${layoutStore.iframeLink}`;
  }
});
// 当前element对应语言的语言包
const elLocale = computed(() => getCurrentLocale(locale.value));
// header 主题
const headerTheme = ref('');
// header参数
const headerProps = computed(() => {
  // 目前首页需要header吸顶
  if (getOriginPath(route?.matched[0]?.path) === '/') {
    return {
      ...layoutStore.routeProps.header,
      theme: headerTheme.value || 'dark',
      border: headerTheme.value === 'light',
      fixed: true
    };
    // // 第一期只有light
    // return {
    //   ...layoutStore.routeProps.header,
    //   theme: 'light',
    //   border: true,
    //   fixed: true
    // };
  }

  return {
    ...layoutStore.routeProps.header,
    fixed: true,
    border: Object.keys(layoutStore.routeProps?.header || {}).includes('border') ? layoutStore.headerFixed : true
  };
});

const fiatPageClass = computed(() => {
  // 判断当前iframe 是否为法币充值页面 需要设置高度
  let fiatRechargePage = layoutStore.iframeLink?.includes('fiatAccount/recharge');
  return fiatRechargePage ? 'fiatPage' : '';
});

const { y } = useWindowScroll();
watch(y, value => {
  if (getOriginPath(route.matched[0].path) === '/') {
    if (value < 499) {
      headerTheme.value = 'dark';
    } else {
      headerTheme.value = 'light';
    }
  }

  layoutStore.headerFixed = value > 10;
});
// 初始化iframe的v-resize
const vResize = {
  mounted: (el: any, { value = {} }) => {
    el?.addEventListener('load', () => iframeResize(value, el));
  },
  unmounted: (el: any) => {
    el?.iFrameResizer?.removeListeners();
  }
};
// iframe配置和监听
const myIframeResize = ref({
  // log: true,
  inPageLinks: true, // 允许iframe调用parent的跳转
  checkOrigin: false,
  onMessage: (msg: any) => {
    if (msg.message['hidden-assets'] != undefined) {
      localStorage.setItem('hidden-assets', String(msg.message['hidden-assets']));
      assetStore.updateHiddenAssets(msg.message['hidden-assets']);
    }
    // pureexchange 登出
    if (msg?.message?.['logoutMsg']) {
      userStore.changeLoginStatus(true);
    }
    // pureexchange 其他设备导致登出
    if (msg?.message?.['logoutOtherEquipmentMsg']) {
      userStore.changeLoginOtherEquipment(true);
    }
    // pureexchange vpn弹窗提示
    if (msg?.message?.['vpnWarning']) {
      if (!userStore?.vpnWarningInfo?.visible) {
        ucApi.vpnBlocked({}).then((info: any) => {
          if (info.code == 200) {
            let userLevel = 1;
            if (msg?.message?.['vpnWarning'] == 200013) userLevel = 2;
            if (msg?.message?.['vpnWarning'] == 200014) userLevel = 3;
            // 6233 vpn提醒lv1弹窗出现undefined，预期：接口拿不到数据就不弹窗
            if ((userLevel == 1 && !info?.data?.vpnRemainDay) || (userLevel == 2 && !info?.data?.certId)) return;
            userStore.changeVpnWarningInfo({ ...info.data, visible: true, userLevel: userLevel });
          }
        });
      }
    }
    // emit sendmessage
    eventEmitter.emit('sendMessage', msg);
  },
  onResized: () => {
    document.querySelector('iframe#hk-iframe')?.iFrameResizer?.sendMessage({
      lang: i18n.global.locale.value,
      legal_flag: 'USD',
      parentOrigin: location.origin,
      parentLocation: window.location,
      hideAmount: localStorage.getItem('hidden-assets') == 'true'
    });
  }
});
// 触发emitter
eventEmitter.on('sendMessage', (msg: any) => {
  layoutStore.changeIframeMsg(msg);
});
// 监听 KycStatus
// watch(() => kyc?.kycInfo?.totalAuditStatus, (newValue: any) => {
//     const isZaFiatDeposit = route.path.includes("/custody/assetView/fiatAccount/deposit/regularTransfer"); // 是否是法币众安充值页面
//     // KYC未通过 需要拦截ZA充值页面
//     if (newValue != null && newValue != CONST.KycTotalStatusEnum.PASS && isZaFiatDeposit) {
//         router.push("/user/kyc");
//     }
// });
// 监听iframeLink
watch(() => layoutStore.iframeLink, (newValue: any) => {
  if (newValue) {
    // 存储当前信息
    localStorage.setItem('iframeInfo', JSON.stringify({
      lang: i18n.global.locale.value,
      legal_flag: 'USD',
      parentOrigin: location.origin,
      parentLocation: window.location
    }));
  } else {
    localStorage.removeItem('iframeInfo');
  }
}, { immediate: true });
// 监听layout iframeMsg
watch(() => layoutStore.iframeMsg as any, value => {
  // toast
  const toastInfo = value?.message?.toastInfo;
  if (toastInfo) {
    toast[`${Object.keys(toastInfo)[0]}`](`${Object.values(toastInfo)[0]}`);
  }
  // link
  if (value?.message?.type == 'link') {
    return router.push(value?.message?.url);
  }
});

// 监听 kycStatus、isLogin 获取合约开通状态
watch([
  () => userStore.isLogin,
  () => kycStatus.value
],
newValue => {
  const [isLogin, kycStatus] = newValue;
  if (isLogin && kycStatus) {
    contractStore.isOpenContract();
  }
});
</script>
<style lang="scss" scoped>
#hk-iframe {
  min-height: 800px;
}
</style>
