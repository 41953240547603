<template>
  <el-dialog
    v-model="dialogVisible"
    title="Waring"
    :width="isPC() ? '400px' : '100%'"
    :modal="true"
    :show-close="false"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="warningCfmTwoDialog"
  >
    <div class="header flex-row items-center flex-row-center">
      <div class="icon-warning" />
    </div>
    <template v-if="type===1">
      <p class="name text-lg-sb gray-900 mt16">
        {{ $t('登录提示') }}
      </p>
      <p class="detail text-sm-r gray-600 mt4">
        {{ t('该手机号被多个用户绑定，请使用邮箱登录后重新绑定其他手机号。谢谢！') }}
      </p>
      <hk-button
        class="false-primary-lg h44 base-white w-full mt32"
        @click="handleClose"
      >
        {{ $t('我知道了') }}
      </hk-button>
    </template>
    <template v-if="type===2">
      <p class="name text-lg-sb gray-900 mt16">
        {{ $t('您已在其他设备登录') }}
      </p>
      <hk-button
        class="false-primary-lg h44 base-white w-full mt32"
        @click="goLogin"
      >
        {{ $t('确认') }}
      </hk-button>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { useUserStore } from '@/store/User';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { isPC } from '@/utils/tools';
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const emits = defineEmits(['up-visible', 'confirm']);
const props = defineProps({
  visible: Boolean,
  type: {
    type: Number,
    default: 1
  }
});
const dialogVisible = computed(() => {
  return props.visible;
});
const handleClose = () => {
  emits('confirm');
  emits('up-visible', false);
};
const goLogin = () => {
  userStore.changeLoginOtherEquipment(false);
  if (userStore.isLogin) {
    // 登出
    userStore.logOut(true);
  }
  // 跳转登录页，保留参数
  router.replace({
    path: '/login',
    params: route.params,
    query: route.query,
    hash: route.hash
  });
};
</script>
  <style lang="scss">
  .warningCfmTwoDialog {
    border-radius: 12px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__footer {
      display: none;
    }
    .el-dialog__body {
      border-radius: 12px;
      background: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
      padding: 24px;
      text-align: center;
      .icon-warning {
        width: 48px;
        height: 48px;
        background: url(@/assets/img/common/icon-warning.svg) center/cover no-repeat;
      }
      .icon-close {
        width: 24px;
        height: 24px;
        background: url(@/assets/img/common/icon-close.svg) center/cover no-repeat;
      }
    }
  }
  </style>
