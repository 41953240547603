// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC, getFlutterH5Prefix } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 活动路由 11个
const activity = [
  {
    path: '/activity',
    component: () => import('@/pages/activity/Index.vue'),
    meta: {
      layout: 'default',
      // auth: 'login',
      title: t('活动'),
      ssg: {}
    },
    redirect: '/activity/api-customer-rewards',
    children: [
      // {
      //   path: 'user-tasks',
      //   component: () => import('@/pages/activity/NewUserTasks.vue'),
      //   meta: {
      //     class: {
      //       layout: 'NewUserTasks-box',
      //       header: 'NewUserTasks-header'
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('新手礼包')
      //   },
      //   redirect: !isPC() ? '/h5/activity/user-tasks' : ''
      // },
      {
        path: 'api-customer-rewards',
        component: () => import('@/pages/api-activity/ApiCustomerRewards.vue'),
        meta: {
          layout: 'default',
          props: { header: { border: false } },
          title: t('API客户奖励')
        },
        redirect: !isPC() ? '/h5/activity/api-customer-rewards' : ''
      },
      {
        path: 'celebration',
        component: () => import('@/pages/api-activity/Celebration.vue'),
        meta: {
          layout: 'default',
          props: { header: { border: false } },
          title: t('专业投资者认证 - 奖励500 HSK - HashKey Global - 买币更方便，存币更安心'),
          description: t('欢迎专业投资者踏上 HSK 开户之路！活动期间提交并完成专业投资者认证即获得 500 HSK！'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/celebration' : ''
      },
      // {
      //   path: 'mbox',
      //   component: () => import('@/pages/activity/mbox/web/index.vue'),
      //   meta: {
      //     class: {
      //       layout: 'NewUserTasks-box',
      //       header: 'NewUserTasks-header'
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('HSK 盲盒'),
      //     ssg: {}
      //   },
      //   redirect: !isPC() ? '/h5/activity/mbox' : ''
      // },
      {
        path: 'mb412',
        component: () => import('@/pages/activity/MB412.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('fs-2023 香港 Web3 Festival 盲盒'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/mb412' : ''
      },
      // {
      //   path: 'ZACard',
      //   component: () => import('@/pages/activity/web/zaCard/Index.vue'),
      //   meta: {
      //     class: {
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('ZA 虚拟卡福利'),
      //     ssg: {}
      //   },
      //   redirect: !isPC() ? '/h5/activity/ZACard' : ''
      // },
      {
        path: 'genesisVip',
        component: () => import('@/pages/activity/web/vip/index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('创世 VIP')
        },
        redirect: !isPC() ? '/h5/activity/genesisVip' : ''
      },
      {
        path: 'mine',
        component: () => import('@/pages/activity/web/mine/index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('交易挖矿'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : '/h5/activity/api'
      },
      {
        path: 'contractTrading',
        component: () => import('@/pages/activity/web/contractTrading/index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('contractTrading'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : '/h5/activity/api'
      },
      {
        path: 'api',
        component: () => import('@/pages/activity/web/api/index.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('contractTrading'),
          ssg: {}
        },
        redirect: isPC() ? '' : '/h5/activity/api'
      },
      {
        path: 'newUserTask',
        component: () => import('@/pages/activity/web/newUserTask/index.vue'),
        meta: {
          class: {
          },
          props: { header: { border: false } },
          title: t('new_user_task_title'),
          keywords: t('new_user_task_keywords'),
          description: t('new_user_task_description'),
          ssg: {}
        }
      },
      {
        path: 'zeroRating',
        component: () => import('@/pages/activity/web/zeroRating/Index.vue'),
        meta: {
          props: { header: { theme: 'dark', border: false }, footer: { theme: 'dark', border: false } },
          title: t('zerorating_meta_title'),
          keywords: t('zerorating_meta_keywords'),
          description: t('zerorating_meta_description'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/zeroRating' : ''
      },
      // {
      //   path: 'LunarNewYearTrading',
      //   component: () => import('@/pages/activity/web/newYearTradeHsk/index.vue'),
      //   meta: {
      //     class: {},
      //     props: { header: { border: false } },
      //     title: t('new-year-2024-迎新春交易有奖活动'),
      //     ssg: {}
      //   },
      //   redirect: !isPC() ? '/h5/activity/LunarNewYearTrading' : ''
      // }
      {
        path: 'futuresFieldTrading',
        component: () => import('@/pages/activity/futuresFieldTrading/web/Index.vue'),
        meta: {
          title: t('futures_field_trading.title'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/futuresFieldTrading' : ''
      },
      {
        path: 'community-trading',
        component: () => import('@/pages/activity/communityTrading/Index.vue'),
        meta: {
          props: { header: { theme: 'dark', border: false }, footer: { theme: 'dark', border: false } },
          title: t('community_trading.title'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/community-trading' : ''
      },
      {
        path: 'newbie-tasks',
        component: () => import('@/pages/activity/newbieTasks/Index.vue'),
        meta: {
          props: { header: { theme: 'dark', border: false }, footer: { theme: 'dark', border: false } },
          title: t('newbie_tasks.title'),
          ssg: {}
        },
        redirect: !isPC() ? '/h5/activity/newbie-tasks' : ''
      }
    ]
  },
  {
    path: '/h5/activity',
    component: () => import('@/pages/activity/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      // auth: 'login',
      title: t('活动'),
      ssg: {}
    },
    redirect: '/h5/activity/api-customer-rewards',
    children: [
      // {
      //   path: 'user-tasks',
      //   component: () => import('@/pages/activity/h5/NewUserTasks.vue'),
      //   meta: {
      //     class: {
      //       layout: 'NewUserTasks-box',
      //       header: 'NewUserTasks-header'
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('邀请好友')
      //   }
      // },
      {
        path: 'api-customer-rewards',
        component: () => import('@/pages/api-activity/h5/ApiCustomerRewards.vue'),
        meta: {
          layout: '',
          props: { header: { border: false } },
          title: t('API客户奖励'),
          ssg: {}
        }
      },
      {
        path: 'celebration',
        component: () => import('@/pages/api-activity/h5/Celebration.vue'),
        meta: {
          layout: 'default',
          props: { header: { border: false } },
          title: t('专业投资者认证 - 奖励500 HSK - HashKey Global - 买币更方便，存币更安心'),
          description: t('欢迎专业投资者踏上 HSK 开户之路！活动期间提交并完成专业投资者认证即获得 500 HSK！'),
          ssg: {}
        }
      },
      // {
      //   path: 'mbox',
      //   component: () => import('@/pages/activity/mbox/h5/index.vue'),
      //   meta: {
      //     class: {
      //       layout: 'NewUserTasks-box',
      //       header: 'NewUserTasks-header'
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('HSK 盲盒'),
      //     ssg: {}
      //   }
      // },
      {
        path: 'visitpc',
        component: () => import('@/pages/activity/visitpc/h5/index.vue'),
        meta: {
          class: {
            layout: 'NewUserTasks-box',
            header: 'NewUserTasks-header'
          },
          props: { header: { border: true } },
          title: ''
        }
      },
      {
        path: 'mb412',
        component: () => import('@/pages/activity/h5/MB412.vue'),
        meta: {
          class: {
          },
          // auth: 'login',
          props: { header: { border: false } },
          title: t('fs-2023 香港 Web3 Festival 盲盒'),
          ssg: {}
        }
      },
      // {
      //   path: 'ZACard',
      //   component: () => import('@/pages/activity/h5/zaCard/Index.vue'),
      //   meta: {
      //     class: {
      //     },
      //     // auth: 'login',
      //     props: { header: { border: false } },
      //     title: t('ZA 虚拟卡福利'),
      //     ssg: {}
      //   },
      //   redirect: isPC() ? '/activity/ZACard' : ''
      // },
      {
        path: 'genesisVip',
        component: () => import('@/pages/activity/h5/vip/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('创世 VIP')
        },
        redirect: isPC() ? '/activity/genesisVip' : ''
      },
      {
        path: 'genesisVip/result/:roundId',
        component: () => import('@/pages/activity/h5/vip/Result.vue'),
        meta: {
          layout: 'h5Default',
          title: t('创世 VIP')
        }
      },
      {
        path: 'genesisVip/mine',
        component: () => import('@/pages/activity/h5/vip/Mine.vue'),
        meta: {
          layout: 'h5Default',
          title: t('我的卡片')
        }
      },
      {
        path: 'genesisVip/card',
        component: () => import('@/pages/activity/h5/vip/Card.vue'),
        meta: {
          layout: 'h5Default'
        }
      },
      // {
      //   path: 'LunarNewYearTrading',
      //   component: () => import('@/pages/activity/h5/newYearTradeHsk/index.vue'),
      //   meta: {
      //     class: {},
      //     props: { header: { border: false } },
      //     title: t('new-year-2024-迎新春交易有奖活动'),
      //     ssg: {}
      //   },
      //   redirect: isPC() ? '/activity/LunarNewYearTrading' : ''
      // },
      {
        path: 'mine',
        component: () => import('@/pages/activity/h5/mine/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('交易挖矿'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : '/h5/activity/api'
      },
      {
        path: 'api/pool',
        component: () => import('@/pages/activity/h5/mine/Pool.vue'),
        meta: {
          layout: 'h5Default',
          title: t('API 交易排名赛奖池详情'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      {
        path: 'api/history/:week',
        component: () => import('@/pages/activity/h5/mine/History.vue'),
        meta: {
          layout: 'h5Default',
          title: t('历史榜单'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      {
        path: 'api/reward/:type',
        component: () => import('@/pages/activity/h5/mine/Reward.vue'),
        meta: {
          layout: 'h5Default',
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      {
        path: 'contractTrading',
        component: () => import('@/pages/activity/h5/contractTrading/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('contractTrading'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : '/h5/activity/api'
      },
      {
        path: 'api/future/pool',
        component: () => import('@/pages/activity/h5/contractTrading/Pool.vue'),
        meta: {
          layout: 'h5Default',
          title: t('API 交易排名赛奖池详情'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      {
        path: 'api/future/history/:week',
        component: () => import('@/pages/activity/h5/contractTrading/History.vue'),
        meta: {
          layout: 'h5Default',
          title: t('历史榜单'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      {
        path: 'api/future/reward/:type',
        component: () => import('@/pages/activity/h5/contractTrading/Reward.vue'),
        meta: {
          layout: 'h5Default',
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      // api
      {
        path: 'api',
        component: () => import('@/pages/activity/h5/api/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('contractTrading'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/api' : ''
      },
      // api end
      {
        path: 'zeroRating',
        component: () => import('@/pages/activity/h5/zeroRating/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('zerorating_meta_title'),
          keywords: t('zerorating_meta_keywords'),
          description: t('zerorating_meta_description'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/zeroRating' : ''
      },
      {
        path: 'futuresFieldTrading',
        component: () => import('@/pages/activity/futuresFieldTrading/h5/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('futures_field_trading.title'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/futuresFieldTrading' : ''
      },
      {
        path: 'futuresFieldTrading/ranking',
        component: () => import('@/pages/activity/futuresFieldTrading/h5/Ranking.vue'),
        meta: {
          layout: 'h5Default',
          title: t('futures_field_trading.previous_ranking'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/futuresFieldTrading' : ''
      },
      {
        path: 'futuresFieldTrading/reward',
        component: () => import('@/pages/activity/futuresFieldTrading/h5/Reward.vue'),
        meta: {
          layout: 'h5Default',
          title: t('futures_field_trading.reward_record'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/futuresFieldTrading' : ''
      },
      {
        path: 'community-trading',
        component: () => import('@/pages/activity/communityTrading/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('community_trading.title'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/community-trading' : ''
      },
      {
        path: 'community-trading/pool',
        component: () => import('@/pages/activity/communityTrading/Pool.vue'),
        meta: {
          layout: 'h5Default',
          title: t('community_trading.prize_pool_detail'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/community-trading' : ''
      },
      {
        path: 'newbie-tasks',
        component: () => import('@/pages/activity/newbieTasks/Index.vue'),
        meta: {
          layout: 'h5Default',
          title: t('newbie_tasks.title'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/newbie-tasks' : ''
      },
      {
        path: 'newbie-tasks/reward',
        component: () => import('@/pages/activity/newbieTasks/Reward.vue'),
        meta: {
          layout: 'h5Default',
          title: t('newbie_tasks.reward.title'),
          ssg: {}
        },
        redirect: isPC() ? '/activity/newbie-tasks' : ''
      }
    ]
  }
];

export default activity;
