import { $contract } from '@/config/api';
import { i18n } from '@/lang/i18n';
import { toast } from '@/utils/toast';
import { useUserStore } from '@/store/User';
import CONST from '@/config/const';

const { t } = i18n.global;

export enum LeverWay{
  'NEW', // 新手
  'MATURE', // 有经验的
  'CUSTOMIZE', // 自定义
}

// 第五步按钮类型 【去交易、入金】或 【划转、入金】
export enum BtnType{
  'Trade',
  'Transfer'
}

// 获取合约问券列表
const F_0 = 'f_o';

const userStore = useUserStore();

export const useContractStore = defineStore('contractStore', {
  state: () => ({
    contractStatus: <boolean | undefined>undefined,
    contractVisible: <boolean>false,
    btnType: <BtnType>BtnType.Transfer,
    first: <boolean>false,
    twice: <boolean>false,
    third: <boolean>false,
    fourth: <boolean>false,
    fifth: <boolean>false,
    transferVisible: <boolean>false,
    statementVisible: <boolean>false, // 声明弹框
    statement: <boolean>false, // 是否确认声明
    leverWay: <LeverWay | undefined>LeverWay.NEW, // 选中的杠杆备注  NEW: 新手  MATURE: 有经验的  CUSTOMIZE: 自定义
    fileCheck: <boolean>false, // 协议勾选
    customizeLever: <string>'1 - 20 x', // 自定义杠杆
    finalLevel: <number|undefined>3,
    questionList: <any[]>[],
    onRefresh: <any>null,
    contractStatusReady: <boolean>false // 标识feature_is_open接口是否请求完成
  }),
  getters: {
    // 合约是否开通  undefined:初始状态 true:开通 false:未开通
    openedContract(state) {
      return state.contractStatus;
    }
  },
  actions: {
    updateStep(val:boolean, step: number) {
      switch (step) {
        case 1:
          this.first = val;
          break;
        case 2:
          this.twice = val;
          break;
        case 3:
          this.third = val;
          break;
        case 4:
          this.fourth = val;
          break;
        case 5:
          this.fifth = val;
          break;
        case 6:
          this.transferVisible = val;
          break;
        case 7:
          this.statementVisible = val;
          break;
      }
    },
    onChooseWay(way: LeverWay) {
      this.leverWay = way;
    },
    updateCustomizeLever(val: string) {
      this.customizeLever = val;
    },
    updateFinalLever(val: number) {
      this.finalLevel = val;
    },
    onAnswer(key:number, val:string) {
      const { answerId } = this.questionList?.[key];
      this.questionList[key].value = val;
      this.questionList[key].result = (answerId === val);
    },
    // 声明确认
    onConfirmStatement(val: boolean) {
      this.statement = val;
    },
    /**
     * 
     * @param visible 合约弹框 visible
     * @param transferCallback 划转成功的callback
     * @param btnType 第五步Button类型 去交易 或 划转
     */
    openVisible(visible = true, transferCallback?: any, btnType?: BtnType) {
      // 子账户登录 和 授权交易员 需要拦截提示
      if (userStore.currentSubAccountId || userStore.userinfo?.userType === CONST.USER_TYPE.AUTHORIZED_TRADER) {
        toast.warning(t('请到主账号开通合约账户'));
        return;
      }
      this.contractVisible = visible;
      this.initData();
      this.btnType = btnType ?? BtnType.Transfer;
      this.onRefresh = transferCallback;
    },
    closeDialog() {
      this.contractVisible = false;
    },
    async getQuestionList() {
      const res:any = await $contract.getQuestion({ name: F_0 });
      if (res?.code == 200) {
        this.questionList = res?.data?.map((x: any) => ({ ...x, value: '' }));
      } else {
        toast.error(res?.message);
        this.questionList = [];
      }
    },
    async onSubmitContract() {
      const { result } = this.questionList?.[0];
      const res: any = await $contract.openContract({
        leverage: this.finalLevel,
        statement: result ? false : this.statement // 第一题回答正确 statement默认为false(代表不需要声明); 
      });
      if (res?.code == 200) {
        this.isOpenContract();
        this.updateStep(false, 4);
        this.updateStep(true, 5);
      } else {
        toast.error(res?.message);
      }
    },
    async isOpenContract() {
      const res: any = await $contract.isOpenContract();
      if (res?.code == 200) {
        this.contractStatus = res?.data;
      } else {
        toast.error(res?.message);
      }
      this.contractStatusReady = true;
    },
    initData() {
      this.leverWay = LeverWay.NEW;
      this.fileCheck = false;
      this.customizeLever = '1 - 20 x';
      this.finalLevel = 3;
      this.questionList = [];
      this.fifth = false;
      this.twice = false;
      this.third = false;
      this.fourth = false;
      this.transferVisible = false;
      this.statementVisible = false;
      this.statement = false;
      this.first = true;
    }

  }

});