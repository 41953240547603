import { pinia } from '@/store/index';
import { useUserStore } from '@/store/User';

const eventTrack = (params: any) => {
  const user = useUserStore(pinia);
  const { userinfo, isNotKycPass, isLogin } =  user;
  var { name, data } = params; // 埋点事件名
  if (!isLogin) { 
    name += '_lo'; 
  } else {
    if (isNotKycPass) { name += '_nk'; }
  }
  const browser = navigator.userAgent; // 获取浏览器信息
  const language = localStorage.getItem('lang') || 'en-US'; // 获取语言
  const timestamp = new Date().getTime(); // 获取事件发生的时间和日期
  const device_type = 'web'; // 设备类型，web端写死web
  const url = location.href; // 事件发生页面的url
  const refer_url = document.referrer; // 上级页面url
  const { userId, userType } = userinfo; // 获取用户信息
  // extend转成string类型
  if (data?.extend) {
    data.extend = JSON.stringify(data.extend);
  }
  const eventValue = Object.assign({
    browser,
    language,
    timestamp,
    userId,
    identity: userType,
    device_type,
    url,
    refer_url
  }, data);

  // console.log("eventValue", eventValue);
  gtag('event', name, { ...eventValue });
};

export default eventTrack;
// // 例子
// import eventTrack from "@/utils/eventTracking";
// eventTrack({ name: "事件名", data: {value: 111, extend: {//业务方要求的额外上报数据}}})

