<template>
  <div class="text-xl-m  align-center main-title mb24">
    {{ $t('开通合约账户') }}
  </div>
  <div class="text-sm-r sub-title mb8">
    {{ $t('永续合约是高风险产品，通过交易期货合约，您有可能放大收益，也有可能会扩大亏损，甚至导致合约账户中的保证金余额被全部强制清算。') }}
  </div>
  <div class="mb24">
    <a :href="getFileLink(4)" target='_blank' class="text-xs-m file-link cursor-pointer">
      {{ $t('点击查看合约交易指南') }}
    </a>
  </div>
  <div class="text-md-m main-title mb12">
    {{ $t('选择默认杠杆倍数') }}
  </div>
  <div class="mb24 flex-row-space-between">
    <div :class="['common-select','cursor-pointer', leverWay === LeverWay.NEW ? 'selected': '']" @click="onChangeWay(LeverWay.NEW)">
      <div class="text-xs-r desc">
        {{ $t('初学者') }}
      </div>
      <span class="text-lg-m value">{{'3 x'}}</span>
    </div>
    <div :class="['common-select','cursor-pointer', leverWay === LeverWay.MATURE ? 'selected': '']" @click="onChangeWay(LeverWay.MATURE)">
      <div class="text-xs-r desc">
        {{ $t('有经验') }}
      </div>
      <span class="text-lg-m value">{{'5 x'}}</span>
    </div>

    <hk-input
      v-model="customizeLever"
      :class="[
        'h62',
        'action-input',
        `${leverWay === LeverWay.CUSTOMIZE ? 'select-customize' : ''}`,
      ]"
      :placeholder="INIT_CUSTOMIZE_LEVEL"
      type="text"
      :formatter="(value:any) =>formatLever(value)"
      @blur="onInputBlur"
      @focus="onInputFocus"
    >
      <template #prefix>
        {{ $t('自定义')  }}
      </template>
      <template #suffix>
        <ExIconEdit v-if="inputBlur" class="cursor-pointer" :size="16" />
      </template>
    </hk-input>

  </div>

  <el-checkbox v-model="fileCheck" :class="['contract-checkbox','w-full']" value="" >
    <div class="text-xs-r link-href cursor-pointer" v-html="$t('继续进行下一步或以其他方式交易永续合约，即表示我已阅读并同意永续合约的 %s 、%n和 %m').replace('%s', `<a href='${getFileLink(1)}' target='_blank' class='primary-400'>${t('客户风险披露')}</a>`).replace('%n',`<a href='${getFileLink(2)}' target='_blank' class='primary-400'>${t('投资者协议')}</a>`).replace('%m', `<a href='${getFileLink(3)}' target='_blank' class='primary-400'>${t('永续合约附加条款')}</a>`)" />
  </el-checkbox>
  <div class="mt24 flex-row-flex-end">
    <hk-button class="text-sm-m contract-btn contract-close-btn" @click="onCancel">
      {{ $t('关闭') }}
    </hk-button>
    <hk-button class=" contract-btn text-sm-m  contract-submit-btn" :disabled="btnDisabled" @click="onSubmit">
      {{ $t('继续') }}
    </hk-button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useCommonStore } from '@/store/common';
import { getLanguage } from '@/lang/i18n';
import { LeverWay, useContractStore } from '@/store/contract';

// 最大杠杆倍数
const MAX_LEVER = 20;
// 自定义杠杆初始值 1-20x
const INIT_CUSTOMIZE_LEVEL = '1 - 20x';

const { t } = useI18n();
const route = useRoute();
const language = getLanguage();
const commonStore = useCommonStore();
const contractStore = useContractStore();
const { fileCheck, leverWay, customizeLever, finalLevel } = storeToRefs(contractStore);
const { updateStep, onChooseWay, updateFinalLever, updateCustomizeLever, closeDialog } = contractStore;

const inputBlur = ref<boolean>(true);

// input-change 校验是否可继续
const btnDisabled = computed(() => {
  if (finalLevel.value && fileCheck.value && leverWay.value !== undefined) {
    return false;
  }
  return true;
});

const getFileLink = (type: number) => {
  const languageStr = language.toLocaleLowerCase();
  const wholeLink = `https://support.global.hashkey.com/hc/${languageStr}`;
  
  switch (type) {
    case 1:
      return `${wholeLink}/articles/13000973243164`;
    case 2:
      return `${wholeLink}/articles/13000983371036`;
    case 3:
      return `${wholeLink}/articles/13490602139932`;
    case 4:
      return `${wholeLink}/categories/13507063598620`;
  }
};

const onInputFocus = () => {
  inputBlur.value = false;
  updateFinalLever(0);
  updateCustomizeLever('');
  onChooseWay(LeverWay.CUSTOMIZE);
};

const onChangeWay = (way: LeverWay) => {
  onChooseWay(way);
  if (way === LeverWay.NEW) {
    updateFinalLever(3);
  } else if (way === LeverWay.MATURE) {
    updateFinalLever(5);
  }
};

const onInputBlur = () => {
  inputBlur.value = true;
  if (customizeLever.value) {
    let str = customizeLever.value;
    const xIndex = str.indexOf('x');
    if (xIndex > 0) {
      updateCustomizeLever(Number(str.slice(0, xIndex)) + ' x');
    } else {
      updateCustomizeLever(customizeLever.value + ' x');
    }
  } else {
    updateFinalLever(0);
  }
};

const formatLever = (val: string) => {
  if (leverWay.value !== LeverWay.CUSTOMIZE) {
    return customizeLever.value;
  }
  let str = val;
  const xIndex = str.indexOf('x');
  if (xIndex > 0) {
    str = val.slice(0, xIndex)?.trim();
  }
  const regex = /^[1-9]\d*$/; // 匹配正整数
  if (str && isNaN(Number(str))) {
    updateFinalLever(0);
    return '';
  } else if (regex.test(str) && Number(str) <= MAX_LEVER) {
    updateFinalLever(Number(str));
    return str + (xIndex > 0 ? ' x' : '');
  } else {
    updateFinalLever(0);
    return '';
  }
};

const onCancel = () => {
  updateStep(false, 2);
  closeDialog();
};
const onSubmit = () => {
  updateStep(false, 2);
  updateStep(true, 3);
};

</script>
<style lang="scss" >
.twiceDialog {
  border-radius: 12px;
  .el-dialog__header {
    display: none;
  }
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    border-radius: 12px;
    background: var(--base-white) !important;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;
    text-align: left !important;

    .icon-close {
      width: 24px;
      height: 24px;
      background: url(@/assets/img/common/icon-close.svg) center/cover no-repeat;
    }
  }
  .link-href {
    color: var(--gray-900);
    text-wrap: wrap;
    white-space: break-spaces;
    width: 100%;
  }
  .main-title {
    color: var(--gray-900);
  }
  .sub-title {
    color: var(--gray-700);
  }
  .detail {
    color: var(--gray-500);
  }

  .common-checkbox {
    width: 16px;
    height: 16px;
  }
  .contract-checkbox {
    width: 496px !important;
    align-items: center;
    height: auto !important;
    
  }

  .file-link {
    color: var(--primary-500);
  }
  .common-select {
    width: 160px;
    height: 62px;
    padding: 8px 16px;
    background: var(--gray-100);
    border-radius: 8px;
    border: 1px solid var(--gray-100);

    .desc {
      color: var(--gray-400);
    }
    .value {
      color: var(--gray-700);
    }

    &:hover {
      border: 1px solid var(--gray-300);
    }

    &.selected {
      border: 1px solid var(--gray-700);

      .desc {
        color: var(--gray-500);
      }
      .value {
        color: var(--gray-900);
      }

    }
   
  }

  .action-input {
    height: 62px;
    width: 160px;

    .el-input__wrapper {
      height: 62px;
      border-radius: 8px !important;
      justify-content: start;
      background: var(--gray-100);
      border: 1px solid var(--gray-100);
      box-shadow: none;

      &.is-focus {
        border: 1px solid var(--gray-700) !important;
        background: var(--base-white);
        box-shadow: 0 1px 2px 0 #1018280d;
      }

      &:hover {
        border: 1px solid var(--gray-300);
      }

      .el-input__prefix {
        font-size: 12px;
        position: absolute;
        top: 8px;
        height: 18px;
        color: var(--gray-500);
      }
      .el-input__suffix {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 16px;
        bottom: 8px;
      }

      .el-input__inner {
        width: 120px;
        color: var(--gray-700);
        position:absolute;
        bottom: 4px;
        font-size: 18px;
        font-weight: 500;
        font-family: 'IBMPlexSans-Medium', 'NotoSansSC-Medium' !important;
      }
    }
    &.select-customize {
      .el-input__wrapper {
        border: 1px solid var(--gray-700);
      }
    }
  }  
}
.dark {
  .action-input {
    background: var(--gray-900) !important; 
    border-radius: 8px;

    .el-input__wrapper {
      border: 1px solid var(--gray-700);
      background: var(--gray-900) !important;

      &.is-focus {
        border: 1px solid var(--gray-300) !important;
        
        .el-input__prefix {
          color: var(--gray-400);
        }
      }

      &:hover {
        border: 1px solid var(--gray-300);
      }

      .el-input__inner {
        color: var(--base-white);
      }
    }
    &.select-customize {
      .el-input__wrapper {
        border: 1px solid var(--gray-300) !important;
      }
    }
  }
  .contract-checkbox {
    width: 16px;
    height: 16px;

    .el-checkbox__inner {
      border-color: var(--gray-300);
    }
    .el-checkbox__input .el-checkbox__inner:hover {
      background-color: var(--gray-700);
      border-color: var(--base-white);
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      background: var(--base-white) !important;
    }

    .el-checkbox__inner::after {
      border: 1px solid var(--gray-900);
      border-left: 0;
      border-top:0;
    }
  }
  
}
</style>
<style lang="scss" scoped>
.dark {
  .twiceDialog {
    .el-dialog__body {
      background: var(--gray-800) !important;
      color: var(--base-white) !important;
      box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
    }
    .align-center {
      text-align: center;
    }
    .link-href {
      color: var(--base-white);
    }
    .main-title {
      color: var(--base-white);
    }
    .sub-title {
      color: var(--gray-300);
    }
    .detail {
      color: var(--gray-400);
    }
    .file-link {
      color: var(--primary-400);
    }
    .common-select {
      background: var(--gray-900);
      border: 1px solid var(--gray-700);

      .desc {
        color: var(--gray-500);
      }
      .value {
        color: var(--base-white);
      }

      &:hover {
        border: 1px solid var(--gray-300);
      }

      &.selected {
        border: 1px solid var(--gray-300);

        .desc {
          color: var(--gray-400);
        }
        .value {
          color: var(--base-white);
        }
      }
    }
  }
}
</style>
